import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "focus-visible/dist/focus-visible.js"

// Components
import Img from "gatsby-image/withIEPolyfill"
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import Stat from "../../components/stat"

// Assets
import activeStakeImage from "../../images/active-stake.svg"
import commonVisionImage from "../../images/common-vision.svg"
import longTermCommitmentImage from "../../images/long-term-commitment.svg"
import iconLinkedin from "../../images/linkedin.svg"
import presentationImage from "../../images/presentation.svg"

const IndexPage = ({ location }) => {
  const translation = {
    href: "/en",
    hrefLang: "en",
  }

  const data = useStaticQuery(graphql`
    query {
      heroBg: file(
        relativePath: { eq: "hero-bg.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        relativePath
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 700, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      philosophyImage: file(
        relativePath: { eq: "philosophy.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 640
            sizes: "(min-width: 1024px) 45vw, 100vw"
            quality: 85
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teamImages: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativePath: {
            in: [
              "team/anne-gauthier-jaques.jpg"
              "team/nathanael-pittet.jpg"
              "team/blaise-luy.jpg"
              "team/cyril-jotterand.jpg"
              "team/yves-jobin.jpg"
              "team/michel-baumann.jpg"
              "team/fabien-besson.jpg"
              "team/carolle-boillat.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(
                maxWidth: 304
                sizes: "(min-width: 1024px) 304px, 264px"
                quality: 85
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      pdf: file(
        relativePath: { eq: "charte-engagement-ABLV-072020.pdf" }
        sourceInstanceName: { eq: "files" }
      ) {
        publicURL
      }
    }
  `)

  const heroImage = data.heroBg.childImageSharp

  const philosophyImage = data.philosophyImage.childImageSharp

  const teamImages = data.teamImages.edges.reduce((obj, item) => {
    return {
      ...obj,
      [item.node.relativePath]: item.node.childImageSharp,
    }
  }, {})

  const pdf = data.pdf

  return (
    <Layout lang="fr" location={location} translation={translation}>
      <SEO
        title="ABLV | Partager une vision, assurer des perspectives"
        description="Soutenir l’économie locale en plaçant l’entrepreneur au centre. ABLV est une société suisse qui détient des participations dans des entreprises."
      />

      {/* Hero */}
      <Hero
        uptitle="ABLV Group"
        title={`Partager une vision <br />Assurer des perspectives`}
        image={heroImage}
      />

      {/* Présentation */}
      <section id="presentation">
        {/* Columns */}
        <div className="pt-20 -mt-20 c-container">
          <h2 className="uptitle">Présentation</h2>
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            {/* Column */}
            <div className="col-span-12 md:max-w-85% lg:max-w-none lg:col-span-6">
              <h1 className="text-3xl leading-tight md:text-4xl xl:text-6xl xxl:text-8xl">
                Soutenir l’économie locale en plaçant l’entrepreneur au centre
              </h1>
              <img
                className="max-w-70% mx-auto sm:mx-0 sm:max-w-50% my-16 lg:hidden"
                src={presentationImage}
                alt=""
              />
              <p className="mt-10 lg:text-lg">
                ABLV Group SA est une société suisse qui détient des
                participations dans des entreprises. Son objectif principal est
                de soutenir l’économie locale en consolidant les entreprises et
                en épaulant les entrepreneurs. ABLV s’appuie à la fois sur des
                compétences métiers permettant de consolider sur le long terme
                ainsi que sur des moyens financiers offrant des perspectives
                tournées vers l'avenir.
              </p>
              <p className="mt-10 lg:text-lg">
                Fort de son ancrage helvétique, elle bénéficie d’une excellente
                connaissance du marché. Elle est active depuis son siège à
                St-Sulpice dans le canton de Vaud.
              </p>
              {/* <div className="flex flex-row flex-wrap mt-16 stat-list lg:mt-24">
                <Stat
                  className="mb-4 mr-4 stat-list__item lg:mb-6 lg:mr-6"
                  number="2022"
                  label="Postes de travail"
                />
                <Stat
                  className="mb-4 mr-4 stat-list__item lg:mb-6 lg:mr-6"
                  number="52"
                  label="Spécialistes métiers"
                />
              </div> */}
            </div>
            {/* Column */}
            <div className="items-start justify-center hidden col-span-4 col-start-8 lg:flex">
              <img src={presentationImage} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Philosophie */}
      <section id="philosophie">
        {/* Columns */}
        <div className="pt-20 -mt-20 c-container">
          <h2 className="uptitle">Notre Philosophie</h2>
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            {/* Column */}
            <div className="col-span-12 md:max-w-85% lg:max-w-none lg:col-span-5">
              <h1 className="text-3xl leading-tight md:text-4xl xl:text-6xl xxl:text-8xl">
                Chaque entreprise est un écosystème, nous participons à le
                pérenniser
              </h1>
              <div className="mt-12 space-y-12 lg:mt-24 lg:space-y-24">
                <div>
                  <img className="mb-8" src={commonVisionImage} alt="" />
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    Partager une vision commune
                  </h3>
                  <p className="lg:text-lg">
                    Nous prenons uniquement des participations dans des
                    entreprises avec lesquelles nous pouvons construire une
                    vision commune tout en respectant la culture d’entreprise
                    établie. Notre présence doit permettre de consolider et de
                    pérenniser l’activité ou d’en accélérer le développement.
                  </p>
                </div>
                <div>
                  <img className="mb-8" src={activeStakeImage} alt="" />
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    Une prise de participation minoritaire mais active
                  </h3>
                  <p className="lg:text-lg">
                    Nous croyons que l’entrepreneur est un pilier central de
                    l’entreprise. Dès lors, nous participons activement à ses
                    côtés comme co-entrepreneur dans le but de consolider et de
                    pérenniser l’activité. C’est pourquoi nos participations
                    sont principalement minoritaires permettant ainsi le respect
                    de l’équilibre qui a fait le succès de l’entreprise.
                  </p>
                </div>
                <div>
                  <img className="mb-8" src={longTermCommitmentImage} alt="" />
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    Investir sur la durée
                  </h3>
                  <p className="lg:text-lg">
                    Notre vision se construit sur la durée et nous n’avons pas
                    pour objectif de céder nos participations. Nous avons la
                    conviction que travailler sur des fondations solides
                    garantit la pérennité de l'entreprise et que cette
                    perspective d’engagement sur le long terme est l’une des
                    clés de la réussite.
                  </p>
                </div>
                <div>
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    Document à télécharger
                  </h3>
                  <a
                    href={pdf.publicURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-icon button-icon--right"
                  >
                    Charte d’engagements d’ABLV
                    <span className="button-icon__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="27"
                        viewBox="0 0 20 27"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          stroke="#FFF"
                          strokeWidth="2"
                        >
                          <path d="M1.0047248 10.5050021L10.0022231 19.5l9.0025017-9M19 25.4977756H1M10 19.5V.5" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {/* Column */}
            <div className="col-span-12 mt-12 lg:mt-0 lg:col-span-6 lg:col-start-7">
              <figure className="lg:text-right lg:sticky lg:top-6">
                <Img {...philosophyImage} />
                <figcaption className="mt-2 text-sm text-gray-200">
                  Photo by Ivan Bandura
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      {/* Compétences */}
      <section id="competences">
        <div className="pt-20 -mt-20 c-container">
          <h2 className="col-span-12 uptitle">Compétences d’ABLV</h2>
          <div className="grid grid-cols-12 col-gap-4 lg:gap-8 md:max-w-85% lg:max-w-none">
            <div className="col-span-12 lg:col-span-7">
              <h1 className="text-3xl leading-tight md:text-4xl xl:text-6xl xxl:text-8xl">
                Des compétences et un appui financier, pour consolider et
                développer
              </h1>
              <p className="mt-10 lg:text-lg">
                ABLV fait bénéficer les sociétés de son groupe à la fois de sa
                force financière et de son réseau de spécialistes. Elle permet
                ainsi d’étendre les compétences des entreprises de manière
                effective.
              </p>
            </div>
          </div>
          <h3 className="mt-12 text-2xl font-bold lg:mt-24">Notre équipe</h3>
          <div className="profile-list flex-wrap md:max-w-85% lg:max-w-none mt-8 flex flex-row">
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/anne-gauthier-jaques.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Anne Gauthier-Jaques</h4>
              <p className="mt-1 mb-4 text-sm ">Légal et RP</p>
              <a
                href="https://www.linkedin.com/in/anne-gauthier-jaques-8b082a32/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/nathanael-pittet.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Nathanaël Pittet</h4>
              <p className="mt-1 mb-4 text-sm">Gestion d’entreprise</p>
              <a
                href="https://www.linkedin.com/in/nathanaelpittet/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/blaise-luy.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Blaise Luy</h4>
              <p className="mt-1 mb-4 text-sm">
                Gestion de projet et processus
              </p>
              <a
                href="https://www.linkedin.com/in/luyblaise/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/cyril-jotterand.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Cyril Jotterand</h4>
              <p className="mt-1 mb-4 text-sm">Ingénierie et technologie</p>
              <a
                href="https://www.linkedin.com/in/cyril-jotterand-59a34922/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/yves-jobin.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Yves Jobin</h4>
              <p className="mt-1 mb-4 text-sm ">
                Gestion de risques et certification
              </p>
              <a
                href="https://www.linkedin.com/in/yves-jobin/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/michel-baumann.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Michel Baumann</h4>
              <p className="mt-1 mb-4 text-sm">Stratégie commerciale</p>
              <a
                href="https://www.linkedin.com/in/michel-baumann-3a51b55/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/fabien-besson.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Fabien Besson</h4>
              <p className="mt-1 mb-4 text-sm">
                Stratégie digitale et de marque
              </p>
              <a
                href="https://www.linkedin.com/in/fabienbesson-superhuit/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/carolle-boillat.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Carole Boillat</h4>
              <p className="mt-1 mb-4 text-sm">
                Assistante de Direction – Coordinatrice
              </p>
              <a
                href="https://www.linkedin.com/in/carole-boillat-5b371129/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                Voir le profil
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
